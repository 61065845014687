<template>
  <div id="app" class="container">
    <social-slide-element
      :layout="layout"
      :content="message"
    ></social-slide-element>
  </div>
</template>

<script>
import BrightSignEmitter from "@/events/BrightsignEmitter";

export default {
  name: "BrightSign",
  components: {
    SocialSlideElement: () =>
      import("@/components/socialslide/SocialSlideElement"),
  },
  props: {
    version: String,
  },
  data() {
    return {
      layout: null,
      messages: null,
      feedId: null,
      messageId: null,
      feeds: null,
      orientation: 0,
      eventEmitter: new BrightSignEmitter(),
    };
  },
  created() {
    this.eventEmitter.listen(this.play);
    if (window.innerWidth < window.innerHeight) {
      this.orientation = 1;
    }
  },
  computed: {
    message: function() {
      if (this.messages && this.messages[this.feedId]) {
        return this.messages[this.feedId].find((m) => m.id === this.messageId);
      }
      return null;
    },
  },
  methods: {
    play: function(data) {
      var syncM = this.syncMessages();
      var syncL = this.syncLayout();
      Promise.all([syncM, syncL])
        .then(() => {
          //play
          this.messageId = data.messageid;
          this.feedId = data.feedid;
        })
        .finally(() => {
          setTimeout(this.end, this.layout.duration);
        });
    },
    end: function() {
      this.eventEmitter.emit("social-slide", "play-next");
    },
    syncLayout: function() {
      return this.$axios
        .get(
          `file:///app/${this.version}/socialslide/layout_${this.orientation}.json`
        )
        .then((result) => {
          this.layout = result.data;
          return result;
        });
    },
    syncMessages: function() {
      return this.$axios
        .get(`file:///app/${this.version}/socialslide/content.json`)
        .then((result) => {
          this.messages = result.data.messages;
          this.feeds = result.data.feeds;
          return result;
        });
    },
  },
};
</script>

<style></style>
