/* eslint-disable no-undef */

import utilities from "../utils/utilities";

export default class BrightSignEmitter {
  constructor() {
    this.port = new BSMessagePort();
    this.callback = {};

    this.port.onbsmessage = (msg) => {
      console.log("onbsmessage", msg);

      var data = {
        event: msg.data.event,
      };

      if (this.callback) {
        switch (data.event) {
          case "play":
            data.zone = JSON.parse(msg.data.zone);
            if (data.zone.content) {
              data.zone.content = utilities.toCamelCase(data.zone.content);
            }
            break;

          case "stop":
            data.zoneId = msg.data.zoneId;
            break;

          case "stopAll":
            //nothing to do
            break;
        }

        console.log("BrightSignEmitter", data);

        this.callback(data);
        return;
      }
      console.log("event not recognized!", msg.data);
    };
  }

  emit(event, data) {
    this.port.PostBSMessage({ event: event, data: data });
  }

  listen(callback) {
    this.callback = callback;
  }
}
